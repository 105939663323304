class RecommendedProducts {
    private addToCartButtonsSelector = ".recommended-product-add-to-cart";
    private itemSelector = ".recommended-product";

    init = () => {
        document.addEventListener("DOMContentLoaded", this.onReady);
    };

    onReady = () => {
        document.addEventListener("click", (event: PointerEvent) => {
            const element = event.target as HTMLElement;

            if (element.matches(this.addToCartButtonsSelector)) {
                this.onAddToCartClick(event);
            }
        });
    };

    onAddToCartClick = async (event: PointerEvent) => {
        event.preventDefault();

        const button = event.target as HTMLElement;

        const parent = button.closest(this.itemSelector);

        if (!parent) {
            return;
        }

        const actionUrl = parent.getAttribute("data-action-url");
        const token = parent.getAttribute("data-token");
        const productId = parent.getAttribute("data-id-product");
        const minimalQuantity = parent.getAttribute("data-minimal-quantity");

        if (!actionUrl || !token || !productId || !minimalQuantity) {
            return;
        }

        const formData = new FormData();
        formData.append("token", token);
        formData.append("id_product", productId);
        formData.append("qty", minimalQuantity);
        formData.append("add", "1");
        formData.append("action", "update");
        formData.append("ajax", "1");

        this.disableButton(button);
        this.setButtonLoadingState(button, true);

        const response = await fetch(actionUrl, {
            method: "POST",
            body: formData,
        });

        if(!response.ok) {
            alert(this.getTranslation('SERVER_ERROR'));
            this.enableButton(button);
            this.setButtonLoadingState(button, false);
            return;
        }

        const data = await response.json();

        if(data.errors) {
            alert(data.errors.join("\n"));
            this.enableButton(button);
            this.setButtonLoadingState(button, false);
            return;
        }

        // thecheckout function to refresh the cart
        if ((window as any).getShippingAndPaymentBlocks !== undefined) {
            (window as any).getShippingAndPaymentBlocks();
        }

        this.setButtonStateAdded(button);
        this.setButtonLoadingState(button, false);
    };

    disableButton = (button: HTMLElement) => {
        button.setAttribute("disabled", "disabled");
    }

    enableButton = (button: HTMLElement) => {
        button.removeAttribute("disabled");
    }

    setButtonLoadingState = (button: HTMLElement, loading: boolean) => {
        button.classList.toggle("loading", loading);
    }

    setButtonStateAdded = (button: HTMLElement) => {
        button.innerText = button.getAttribute("data-added-text");
    }

    getTranslation = (key: string) => {
        if (!(window as any).arkonrecommendedproducts && !(window as any).arkonrecommendedproducts.translations) {
            return key;
        }

        return (window as any).arkonrecommendedproducts.translations[key] || "";
    }
}

const recommendedProducts = new RecommendedProducts();
recommendedProducts.init();
